<template>
  <div class="main">
    <div class="main__top">
      <v-btn @click="category_show=true" class="add__btn" color="primary">
        <v-icon left>mdi-plus</v-icon>
        Добавить категорию
      </v-btn>
      <v-btn icon @click="$router.push('/cash')">
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>
    </div>

    <v-container>
      <v-row>
        <v-col v-for="(category, index) in categories" :key="index" cols="12" md="4">
          <v-card>
            <v-card-title>{{ category.name }}</v-card-title>
            <v-card-actions>
              <v-btn icon @click="editCategory(category)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="category_show" max-width="500px">
      <v-card>
        <v-card-title>Добавить категорию</v-card-title>
        <v-card-text>
          <v-text-field v-model="name" label="Название" required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveCategory">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="edit_category_show" max-width="500px">
      <v-card>
        <v-card-title>Редактировать категорию</v-card-title>
        <v-card-text>
          <v-text-field v-model="name" label="Название" required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="editSaveCategory">Редактировать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Mailing",
  data() {
    return {
      categories: [],
      category_show: false,
      edit_category_show: false,
      name: '',
      category_id: null,
    };
  },
  methods: {
    editSaveCategory() {
      this.$axios.put(`${this.$API_URL}category/${this.category_id}`, { name: this.name }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
          .then(res => {
            this.edit_category_show = false;
            this.getCategories();
            this.$toast.open({ message: res.data.message, type: "success" });
          })
          .catch(error => {
            this.$toast.open({ message: error.response.data.error, type: "error" });
          });
    },
    editCategory(category) {
      this.name = category.name;
      this.category_id = category.id;
      this.edit_category_show = true;
    },
    getCategories() {
      this.$axios.get(`${this.$API_URL}category`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
          .then(res => { this.categories = res.data; })
          .catch(console.error);
    },
    saveCategory() {
      this.$axios.post(`${this.$API_URL}category`, { name: this.name }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
          .then(res => {
            this.category_show = false;
            this.getCategories();
            this.$toast.open({ message: res.data.message, type: "success" });
          })
          .catch(error => {
            this.$toast.open({ message: error.response.data.error, type: "error" });
          });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 20px;
}

.main__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
